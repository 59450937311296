import React, { useState } from "react"
import { ArrowSmUpIcon, ShareIcon, FlagIcon } from '@heroicons/react/solid'
import { Suggestion as SuggestionType } from '../types'
import { onVote, formatTime, onFlag } from "../service/main"
import FlaggedModal from '../components/FlaggedModal'

type Props = {
    suggestion: SuggestionType
    userId?: string
    shareAction: (showShareModal: SuggestionType) => void
}

const Suggestion: React.FC<Props> = ({ suggestion, userId, shareAction }) => {
    const voted = suggestion.upVotes?.find(id => id === userId)
    const flagged = suggestion.flaggedBy?.find(id => id === userId)

    const [showModal, setShowModal] = useState(false)

    return (
        <div className={`m-4 p-4 text-center bg-white rounded-lg border shadow-md ${suggestion.selected ? ' border-blue-200' : ''}`}>

            <div className="flex flex-row h-auto">
                <div className="mr-2 flex flex-col items-center">
                    <ArrowSmUpIcon className={`h-5 w-5 text-blue-500 hover:bg-blue-100 hover:cursor-pointer ${voted ? 'bg-blue-100' : ''}`} onClick={() => onVote(suggestion, userId)} />
                    <p className="text-gray-300 text-sm">{suggestion.votes}</p>
                </div>

                <div className="w-full justify-start items-start content-start flex flex-col space-y-4 sm:space-y-0 pb-2">
                    <p className="text-gray-400 text-sm text-left mb-4"> {suggestion.text}</p>

                    <span className="h-0.5 w-full mb-2 flex bg-gray-100 self-start"> </span>

                    <div className="pt-2 flex flex-row space-x-2 items-center w-full justify-between">
                        <div className="flex flex-row space-x-2 items-center" >
                            <div className="relative w-5 h-5 overflow-hidden bg-gray-100 rounded-full">
                                <svg className="absolute w-7 h-7 text-gray-400 -left-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"></path></svg>
                            </div>

                            <p className="text-gray-400 text-xs text-left"> Annonymous user  </p>

                            {suggestion.dateCreated && <p className="text-gray-400 text-xs text-left">{formatTime(suggestion.dateCreated)}</p>}
                        </div>

                        <div className="flex flex-row space-x-2">
                            <FlagIcon onClick={() => setShowModal(true)} className={`h-4 w-4 hover:text-red-400 self-end hover:cursor-pointer ${flagged ? 'bg-red-100 text-red-400' : 'text-slate-300'}`} />

                            <ShareIcon className={`h-4 w-4 text-slate-300 hover:text-blue-400 self-end hover:cursor-pointer`} onClick={() => shareAction(suggestion)} />
                        </div>
                    </div>
                </div>
            </div>


            <FlaggedModal showModal={showModal} onClose={() => setShowModal(false)} onComplete={() => {

                onFlag(suggestion, userId)
                setShowModal(false)

            }} />
        </div>
    )
}


export default Suggestion   