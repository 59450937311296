import React, { useState } from "react";
import { HeartIcon, ArrowCircleRightIcon, ArrowCircleLeftIcon } from '@heroicons/react/solid'

type Props = {
    showModal: boolean
    onClose: () => void
}


const Modal: React.FC<Props> = ({ showModal, onClose }) => {
    const [sectionToShow, setSectionToShow] = useState('welcome')

    const getNewSection = () => {
        return sectionToShow === 'welcome' ? 'rules' : 'welcome'
    }

    const onModalClose = () => {
        setSectionToShow('welcome');
        onClose()
    }

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
                    >
                        <div className="relative w-auto lg:h-auto md:h-auto h-auto my-6 mx-4 max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-center justify-between p-2 bg-slate-50  border-b border-solid border-slate-200 rounded-t">
                                    <div className="flex flex-row w-full space-x-3 pt-2 pl-2">
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                    </div>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 hover:text-slate-300  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={onModalClose}
                                    >
                                        <span className="bg-transparent text-slate-500  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">

                                    {sectionToShow === 'welcome' && <>
                                        <div className="flex flex-row space-x-1">
                                            <h3 className="text-base font-medium animate-pulse text-blue-500">
                                                Welcome to
                                            </h3>
                                            <h3 className="text-base font-medium text-slate-700 justify-center items-center flex flex-row">
                                                <svg className="fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                    <path d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                                Bitetales
                                            </h3>
                                        </div>
                                        <p className="mb-2 mt-1 text-slate-500 text-sm leading-relaxed">
                                            Tell an amazing story together!
                                        </p>
                                        <p className="py-1 text-slate-500 text-sm leading-relaxed">
                                            Each day is a new theme for a story, e.g. Fairy Tale, comedy
                                        </p>
                                        <p className="py-1 text-slate-500 text-sm leading-relaxed">
                                            Users add suggestions for the next paragraph in the story
                                        </p>

                                        <p className="py-1 text-slate-500 text-sm leading-relaxed">
                                            Every hour, the suggestion with the highest votes is added to the story.
                                        </p>
                                        <p className="py-1 text-slate-500 text-sm leading-relaxed">
                                            A story lasts for 24 hours!
                                        </p>

                                        <p className="my-1 text-slate-500 text-sm leading-relaxed">
                                            Simples!
                                        </p>
                                    </>}

                                    {/*rules*/}
                                    {sectionToShow === 'rules' && <div className="border-solid border-slate-200">
                                        <div className="flex flex-row space-x-1">
                                            <h3 className="text-base font-medium animate-pulse text-red-400">
                                                Don't break the
                                            </h3>
                                            <h3 className="text-base font-medium text-slate-700 justify-center items-center flex flex-row">
                                                <svg className="fill-current text-gray-700" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                    <path d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                                                </svg>
                                                Rules
                                            </h3>
                                        </div>
                                        <ul className="">
                                            <li>
                                                <p className="py-1 text-slate-500 text-sm">
                                                    1. <strong> No spam.</strong> i.e automated messages, advertisements, and
                                                    links to websites
                                                    will be deleted </p>
                                            </li>
                                            <li>
                                                <p className="py-1 text-slate-500 text-sm"> 2. <strong> Respect other users. </strong> No inflammatory or abusive
                                                    comments. Posts
                                                    containing inflammatory or abusive comments will be deleted.</p>
                                            </li>
                                            <li>
                                                <p className="py-1 text-slate-500 text-sm"> 3. <strong> Adult content.</strong> No profanity or pornography is allowed. Posts containing
                                                    adult material will be deleted.</p>
                                            </li>
                                            <li>
                                                <p className="py-1 text-slate-500 text-sm"> 4. <strong>Illegal content.</strong> No re-posting of copyrighted materials or other illegal
                                                    content is allowed. Any posts containing illegal content or copyrighted
                                                    materials will be deleted. </p>
                                            </li>
                                        </ul>

                                    </div>}

                                    <div className="flex justify-end space-x-2">
                                        <button onClick={() => setSectionToShow(getNewSection())} className="rounded-full flex w-8 h-8 justify-center items-center bg-slate-200">
                                            {sectionToShow === 'welcome' ?
                                                <ArrowCircleRightIcon className="text-blue-400 w-5 h-5 self-center" /> :

                                                <ArrowCircleLeftIcon className="text-blue-400 w-5 h-5 self-center" />
                                            }
                                        </button>

                                        {sectionToShow === 'rules' && <button onClick={onClose} className="hover:bg-slate-400 hover:cursor-pointer group items-center p-2 rounded-md bg-slate-500 text-white text-xs font-medium shadow-sm">
                                            Done
                                        </button>}
                                    </div>


                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end pr-4 border-t border-solid border-slate-200 rounded-b py-2">

                                    <p className="text-slate-500 text-sm leading-relaxed"> Built with</p>
                                    <HeartIcon className="h-4 w-4 text-blue-400 animate-bounce hover:bg-blue-100 hover:cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}


export default Modal