import React, { useEffect, useState } from 'react'

import './App.css';
import Nav from './components/Nav';
import PanelHeader from './components/PanelHeader';
import Paragraphs from './components/Paragraphs';
import { getAllCompletedStories } from './service/main'
import { Story } from './types'

const Stories: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [stories, setStories] = useState<Story[]>([])

    useEffect(() => {
        const fetchStories = async () => {
            setLoading(true)
            const result = await getAllCompletedStories();
            setStories(result);
            setLoading(false)
        }

        fetchStories();
    }, [])

    const convertDate = (story: Story) => {
        return new Date(story.dateCreated.seconds * 1000).toDateString()
    }

    return (
        <div className="App dark:bg-white">
            <Nav />
            <div className="container mx-auto">
                <div className="grid grid-cols-1 gap-y-2 lg:gap-2 md:gap-2 xl:px-0 px-4">

                    {/* Right Panel */}
                    <div className="bg-white text-black-500 border-2 rounded-lg lg:h-full  md:h-full h-auto mb-4">
                        <PanelHeader title='Stories' />


                        <div className="lg:px-40 md:px-20 px-2">
                            {
                                stories.map((story) => {
                                    if (!story.paragraphs) return null;
                                    return (
                                        <div key={story.id} className="border-t-2 border-slate-50 py-5">

                                            <div className="flex lg:flex-row md:flex-row flex-col w-full">
                                                <div className="flex flex-row space-x-1 lg:w-1/2 md:w-1/2 self-start py-2">
                                                    <p className="text-gray-600 text-xs lg:font-bold font-semibold my-4">
                                                        Theme:
                                                    </p>

                                                    <div className="flex justify-center items-center lg:m-1 md:m-1 px-2 py-1 border border-gray-300 rounded-full text-base whitespace-nowrap text-gray-400 font-medium">
                                                        <div className="flex-initial max-w-full leading-none text-xs font-sm">{story.theme}</div>
                                                    </div>
                                                </div>


                                                <div className="flex flex-row space-x-1 lg:w-1/2 md:w-1/2 lg:justify-end md:justify-end lg:self-end md:self-end justify-start self-start py-2">
                                                    <p className="text-gray-600 text-xs lg:font-bold font-semibold my-4">
                                                        Date:
                                                    </p>

                                                    <div className="flex justify-center items-center lg:m-1 md:m-1 px-2 py-1 border border-gray-300 rounded-full text-base whitespace-nowrap text-gray-400 font-medium">
                                                        <div className="flex-initial max-w-full leading-none text-xs font-sm">{convertDate(story)}</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <ul className="list-reset items-center pt-3 my-1">
                                                <li>
                                                    <p className="text-gray-600 text-base pb-2">
                                                        {story.text}
                                                    </p>
                                                </li>

                                                <Paragraphs paragraphs={story.paragraphs} />
                                            </ul>


                                        </div>
                                    )

                                })
                            }

                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
}

export default Stories;
