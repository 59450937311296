import React, { useEffect, useState } from 'react'
import { ArrowCircleUpIcon } from '@heroicons/react/solid'
import './App.css';
import { ReactComponent as LaptopMan } from './img/laptop-man.svg';

import Nav from './components/Nav';
import Suggestion from './components/Suggestion';
import ProgressBar from './components/ProgressBar';
import SubmitForm from './components/SubmitForm';
import Paragraphs from './components/Paragraphs';
import SortSuggestions from './components/SortSuggestions';
import ShareModal from './components/ShareModal';
import PanelHeader from './components/PanelHeader';
import EmptyScreen from './components/EmptyScreen';

import {
  Story,
  Suggestion as SuggestionType,
  SuggestionOrderBy,
  SuggestionOrderByType,
  Paragraphs as ParagraphsType
} from './types';

import {
  getCurrentStory,
  getAllSuggestionsListener,
  getAllParagraphsListener,
  authenticateAnonymousUser,
  onAuthStateChangedListener
} from './service/main'
import Modal from './components/Modal';
import { useLocalStorage } from './hooks/uselocalStorage';
import { useProgressBar } from './hooks/useProgressBar';

const App: React.FC = () => {
  const hasViewedWelcomeModal = 'hasViewedWelcomeModal'
  const [currentStory, setCurrentStory] = useState<Story | null>(null);
  const [suggestions, setSuggestions] = useState<SuggestionType[]>([]);
  const [paragraphs, setParagraphs] = useState<ParagraphsType[]>([]);

  const [loading, setLoading] = useState(false);
  const [suggestionsOrder, setSuggestionsOrder] = useState<SuggestionOrderByType>(SuggestionOrderBy.all)
  const [anonymousUserId, setAnonymousUserId] = useState<string>()

  const [showModal, setShowModal] = useState(false);
  const [shareContent, setShareContent] = useState<SuggestionType | null>(null)

  const [userSuggestion, setUserSuggestion] = useState<string>('')

  const [value, setValue] = useLocalStorage(hasViewedWelcomeModal, false)

  const { storyProgress, suggestionProgress } = useProgressBar(currentStory)

  // Show modal for the first time 
  useEffect(() => {
    if (value) return
    setShowModal(true)
  }, [value])

  //Authenticate user as anonymous 
  useEffect(() => {
    if (!value) return
    // console.log('Authenticate Annonymous useEffect ==>')

    authenticateAnonymousUser();
    onAuthStateChangedListener(setAnonymousUserId)
  }, [value])

  //Fetch current story
  useEffect(() => {
    if (!value || !anonymousUserId || currentStory) return
    // console.log('fetch CurrentStory useEffect ==>')

    const fetchCurrentStory = async () => {
      const data: Story | null = await getCurrentStory()
      setCurrentStory(data)
    }

    setLoading(true);
    fetchCurrentStory();
    setLoading(false);
  }, [value, anonymousUserId, currentStory])

  //Fetch Paragraphs
  useEffect(() => {
    if (!currentStory) return

    const unsubscribe = getAllParagraphsListener(currentStory.id, (paragrahps: ParagraphsType[]) => {
      setParagraphs(paragrahps)
    })

    return () => {
      unsubscribe()
    }

  }, [currentStory])

  //Fetch suggestions 
  useEffect(() => {
    if (!currentStory) return

    const unsubscribe = getAllSuggestionsListener(currentStory.id, (suggestions: SuggestionType[]) => {
      setSuggestions(suggestions)
    }, suggestionsOrder)

    return () => {
      unsubscribe()
    }

  }, [currentStory, suggestionsOrder])


  return (
    <div className="App dark:bg-white">
      <Nav />

      {currentStory &&
        <div className="container mx-auto">
          <div className="grid lg:grid-cols-5 md:grid-cols-5 grid-cols-1 gap-y-2 lg:gap-2 md:gap-2">

            {/* Left panel */}
            <div className="bg-white text-black-500 border-2 rounded-lg lg:h-screen md:h-screen h-fit col-span-2 lg:overflow-y-auto md:overflow-y-auto lg:order-1 md:order-1 order-2">
              <PanelHeader title='Suggestions' />

              {/* Left Panel header */}
              <div className="py-2 mx-4 flex lg:flex-row  flex-col justify-between lg:space-y-0 space-y-4 border-b-2 border-slate-100 gap-2">
                {/* Progress bar */}
                <div className="lg:order-1 md:order-2 order-2 lg:mt-0 mt-4">
                  <ProgressBar title='Paragraph ends in:' progress={suggestionProgress} />
                </div>

                <SortSuggestions suggestionsOrder={suggestionsOrder} setSuggestionsOrder={setSuggestionsOrder} />

              </div>

              <div className="flex flex-col h-full w-full">

                {
                  suggestions.map((suggestion) =>
                    <Suggestion key={suggestion.id} suggestion={suggestion} userId={anonymousUserId} shareAction={setShareContent} />
                  )
                }

                {suggestions.length === 0 && <div className="flex flex-col h-fit justify-center space-y-2">
                  <div className="flex flex-col whitespace-nowrap justify-center self-center items-center rounded-full w-40 h-40 opacity-80 bg-slate-100 mt-10">
                    <LaptopMan className="w-24 h-24 text-gray-600 opacity-90" />

                  </div>

                  <div className="flex flex-row justify-center items-center space-x-2">
                    <p className="text-gray-500 text-xs"> Be the first to add a suggestion</p>

                    <div className="flex justify-end lg:hidden md:hidden">
                      <button onClick={() => { window.scrollTo(0, 0) }} className="rounded-full flex w-8 h-8 justify-center items-center bg-slate-200">
                        <ArrowCircleUpIcon className="text-blue-400 w-5 h-5 self-center" />
                      </button>
                    </div>
                  </div>
                </div>}
              </div>

            </div>

            {/* Right Panel */}
            <div className="bg-white text-black-500 border-2 overflow-hidden rounded-lg lg:h-screen md:h-screen h-auto col-span-3 lg:overflow-y-auto md:overflow-y-auto lg:order-2 md:order-2 order-1">
              <PanelHeader title='Story' />

              <div className="px-4">

                <div className="lg:mx-4 md:mx-4 py-2 flex lg:flex-row md:flex-row flex-col justify-between border-b-2 border-slate-100 lg:space-y-0 md:space-y-0 spcae-y-4">

                  <div className="flex flex-row gap-1 lg:w-1/2 md:w-1/2 lg:self-start md:self-start self-end ">
                    <p className="text-gray-600 text-xs lg:font-bold font-semibold my-4">
                      Theme:
                    </p>

                    <div className="flex justify-center items-center lg:m-1 md:m-1 px-2 py-1 border border-gray-300 rounded-full text-base whitespace-nowrap text-gray-400 font-medium">
                      <div className="flex-initial max-w-full leading-none text-xs font-sm">{currentStory.theme}</div>
                    </div>

                  </div>

                  {/* progress bar  */}
                  <div className="lg:mt-0 mt-4">
                    <ProgressBar title='Story ends in:' progress={storyProgress} />
                  </div>
                </div>


                {/* Submit form  */}
                <SubmitForm
                  storyId={currentStory.id}
                  userSuggestion={userSuggestion}
                  setUserSuggestion={setUserSuggestion}
                  anonymousUserId={anonymousUserId}
                  setShareContent={setShareContent} />

                {/* Story so far */}
                <ul className="list-reset items-center pt-3 lg:px-4 md:px-4 my-1">
                  <li>
                    <p className="text-gray-600 text-base pb-2">
                      {currentStory?.text}
                    </p>
                  </li>

                  <Paragraphs paragraphs={paragraphs} />

                  <li>
                    <p className="text-gray-400 break-words text-base pb-2">
                      {userSuggestion}
                    </p>
                  </li>
                </ul>

              </div>

            </div>
          </div>

        </div>
      }

      {
        !loading && !currentStory && <EmptyScreen />
      }

      <Modal showModal={showModal} onClose={() => {
        setShowModal(false)
        setValue(true)
      }} />

      <ShareModal shareContent={shareContent} onClose={() => { setShareContent(null) }} />

    </div>
  );
}

export default App;
