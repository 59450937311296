import React from "react";

type Props = {
    progress: { progress: number, timeLeft: string },
    title: string
}
const ProgressBar: React.FC<Props> = ({ progress, title }) => {
    return (
        <div className="flex-1 justify-center flex flex-col items-center space-y-2">
            <div className="flex w-full justify-between space-x-2">
                <span className="flex text-xs whitespace-nowrap p-2 items-center rounded-full text-slate-600 bg-slate-200">
                    {title}
                </span>
                <span className="flex self-center justify-end text-slate-300 text-xs">{`${progress.timeLeft} left`}</span>
            </div>
            <div className="flex flex-col w-full space-x-1">
                <div className="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700">
                    <div className="bg-gray-400 h-1.5 rounded-full dark:bg-gray-300" style={{ width: `${progress.progress}%` }}></div>
                </div>
            </div>
        </div>
    )

}


export default ProgressBar