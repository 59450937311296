import React from "react";
import { HeartIcon } from '@heroicons/react/solid'
import '../css/shareModal.css';


type Props = {
    showModal: boolean
    onClose: () => void,
    onComplete: () => void
}

const FlaggedModal: React.FC<Props> = ({ showModal, onClose, onComplete }) => {

    return (
        <>
            {showModal ? (
                <>
                    <div
                        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
                    >
                        <div className="relative w-auto my-6 mx-4 lg:mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-center justify-between p-2 bg-slate-50  border-b border-solid border-slate-200 rounded-t">
                                    <div className="flex flex-row w-full space-x-3 pt-2 pl-2">
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                    </div>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 hover:text-slate-300  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={onClose}
                                    >
                                        <span className="bg-transparent text-slate-500  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="flex flex-row space-x-1">
                                        <h3 className="text-base font-medium animate-pulse text-red-500">
                                            Flag
                                        </h3>
                                    </div>

                                    <div className="flex flex-row space-x-4 items-center justify-center">
                                        <div>
                                            <p className="py-1 text-left text-slate-500 text-sm">Flagging this suggestion means it breaks our rules  </p>
                                            <p className="py-1 text-left text-slate-500 text-sm">We will review this suggesion and remove it if it breaks our rules.</p>
                                        </div>
                                        <div>
                                            {/* Sharingbutton Copy */}

                                            <button onClick={onComplete} className="hover:bg-slate-400 hover:cursor-pointer group flex items-center justify-center rounded-md bg-slate-500 text-white text-sm font-medium pl-2 pr-3 py-4 shadow-sm space-x-2">
                                                <p className="text-white text-sm leading-relaxed">OK</p>
                                            </button>

                                        </div>
                                    </div>
                                </div>


                                {/*footer*/}
                                <div className="flex items-center justify-end pr-4 border-t border-solid border-slate-200 rounded-b py-2">

                                    <p className="text-slate-500 text-sm leading-relaxed"> Built with</p>
                                    <HeartIcon className="h-4 w-4 text-blue-400 animate-bounce hover:bg-blue-100 hover:cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}


export default FlaggedModal