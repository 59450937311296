import React from "react";
import { Paragraphs as ParagraphsType } from "../types";


type Props = {
    paragraphs: ParagraphsType[]
}

const Paragraphs: React.FC<Props> = ({ paragraphs }) => {

    return (
        <>
            {
                paragraphs.map((paragraph) => (
                    <li className="list-none pb-2" key={paragraph.id}>
                        <p className="text-gray-600 text-base pb-2">
                            {paragraph.text}
                        </p>
                    </li>
                ))
            }
        </>
    )

}

export default Paragraphs