import React from 'react'
import PanelHeader from './PanelHeader'
import { ReactComponent as LaptopMan } from '../img/laptop-man.svg';
const EmptyScreen = () => {
    return (
        <div className="container mx-auto">
            <div className="grid grid-cols-1 gap-y-2 lg:gap-2 md:gap-2 xl:px-0 px-4">
                <div className="bg-white text-black-500 border-2 rounded-lg lg:h-screen md:h-screen h-auto mb-4">
                    <PanelHeader title='' />

                    <div className="flex flex-col h-screen w-auto justify-center items-center space-y-2">
                        <div className="flex flex-col whitespace-nowrap justify-center animate-pulse self-center items-center rounded-full w-40 h-40 opacity-80 bg-slate-100">
                            <LaptopMan className="w-24 h-24 text-gray-600 opacity-90" />
                        </div>

                        <div className="flex flex-row justify-center items-center space-x-2">
                            <p className="text-gray-500 text-center text-xs">There isn't a story live at the moment. Please check back later.</p>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default EmptyScreen