import React from 'react'
import { useState } from 'react'
import { Link } from "react-router-dom";
import { ViewListIcon } from '@heroicons/react/solid'

import Modal from './Modal'

const Nav: React.FC = () => {
    const [showModal, setShowModal] = useState(false)
    const [toggleMobileMenu, setToggleMobileMenu] = useState(false)

    return (
        <nav id="header" className="bg-white w-full z-30 top-0 py-1">
            <div className="w-full container mx-auto flex flex-row items-center justify-between mt-0 px-6 py-3">

                {/* <!-- Mobile menu button --> */}
                <div className="md:hidden visible flex items-center">
                    <button className="outline-none" onClick={() => setToggleMobileMenu(!toggleMobileMenu)}>
                        <ViewListIcon className="W-5 h-5 text-slate-600" />
                    </button>
                </div>

                <div className={`${toggleMobileMenu ? 'vissible absolute top-12 left-0 w-full' : 'hidden'}`}>
                    <nav>
                        <ul className="flex flex-col drop-shadow-md px-6 bg-white border-1 rounded-md items-start justify-between text-base text-gray-700">
                            <li><Link className="inline-block no-underline hover:text-black hover:underline text-sm py-2" to="/">Home</Link></li>
                            <li><button onClick={() => setShowModal(true)} className="inline-block no-underline hover:text-black hover:underline text-sm py-2 ">About</button></li>
                            <li><Link className="inline-block no-underline hover:text-black hover:underline text-sm py-2" to="/stories">Stories</Link></li>
                        </ul>
                    </nav>
                </div>

                <div className="lg:vissible md:visible hidden md:flex md:items-center md:w-auto w-full" id="menu">
                    <nav>
                        <ul className="md:flex items-center justify-between text-base text-gray-700 md:pt-0">
                            <li><Link className="inline-block no-underline hover:text-black hover:underline text-sm py-2 px-4" to="/">Home</Link></li>
                            <li><button onClick={() => setShowModal(true)} className="inline-block no-underline hover:text-black hover:underline text-sm py-2 px-4">About</button></li>
                            <li><Link className="inline-block no-underline hover:text-black hover:underline text-sm py-2 px-4" to="/stories">Stories</Link></li>
                        </ul>
                    </nav>
                </div>

                <div className="w-auto">
                    <a className="flex items-center tracking-wide no-underline hover:no-underline font-bold text-gray-700 lg:text-xl md:text-lg text-sm whitespace-nowrap" href="#">

                        <svg className="fill-current text-gray-800 mr-2" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
                        </svg>
                        Bitetales
                    </a>
                </div>

                {/* <div className="order-2 md:order-3 flex items-center" id="nav-content">

                    <a className="inline-block no-underline hover:text-black" href="#">
                        <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <circle fill="none" cx="12" cy="7" r="3" />
                            <path d="M12 2C9.243 2 7 4.243 7 7s2.243 5 5 5 5-2.243 5-5S14.757 2 12 2zM12 10c-1.654 0-3-1.346-3-3s1.346-3 3-3 3 1.346 3 3S13.654 10 12 10zM21 21v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h2v-1c0-2.757 2.243-5 5-5h4c2.757 0 5 2.243 5 5v1H21z" />
                        </svg>
                    </a>

                    <a className="pl-3 inline-block no-underline hover:text-black" href="#">
                        <svg className="fill-current hover:text-black" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path d="M21,7H7.462L5.91,3.586C5.748,3.229,5.392,3,5,3H2v2h2.356L9.09,15.414C9.252,15.771,9.608,16,10,16h8 c0.4,0,0.762-0.238,0.919-0.606l3-7c0.133-0.309,0.101-0.663-0.084-0.944C21.649,7.169,21.336,7,21,7z M17.341,14h-6.697L8.371,9 h11.112L17.341,14z" />
                            <circle cx="10.5" cy="18.5" r="1.5" />
                            <circle cx="17.5" cy="18.5" r="1.5" />
                        </svg>
                    </a>

                </div> */}
            </div>

            <Modal showModal={showModal} onClose={() => { setShowModal(false) }} />

        </nav>
    )

}

export default Nav