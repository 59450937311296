import { fireStore } from './firebase'
import { DateTime, DurationLikeObject } from "luxon";
import {
    collection,
    addDoc,
    getDocs,
    doc,
    serverTimestamp,
    runTransaction,
    onSnapshot,
    Unsubscribe,
    query,
    orderBy,
    updateDoc,
    arrayUnion,
    arrayRemove,
    DocumentReference,
    where,
    QuerySnapshot,
    DocumentData
} from "firebase/firestore";
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import { Story, Suggestion, Timestamp, Paragraphs, SuggestionOrderBy } from '../types';

import { DEFAULT_STORY_TIME, DEFAULT_SUGGESTION_TIME } from '../config';

//TODO: Handle errors simple error system 

export const onVote = async (suggestion: Suggestion, userId?: string) => {
    try {
        const docRef = doc(fireStore, `story/${suggestion.storyId}/suggestions/${suggestion.id}`)
        const alreadyVoted = suggestion.upVotes?.find((id) => id === userId)

        await runTransaction(fireStore, async (transaction) => {

            const sfDoc = await transaction.get(docRef);

            if (!sfDoc.exists()) {
                throw "Document does not exist! ==>";
            }

            let newVotes = 0
            if (alreadyVoted) {
                newVotes = sfDoc.data().votes - 1
            } else {
                newVotes = sfDoc.data().votes + 1;
            }
            transaction.update(docRef, { votes: newVotes });
        });

        await updateUpVotesArray(docRef, alreadyVoted, userId)
        // console.log("Transaction successfully committed! ==>");
    } catch (e) {
        console.log("Transaction failed: ==>", e);
    }
}

export const onFlag = async (suggestion: Suggestion, userId?: string) => {
    try {
        const docRef = doc(fireStore, `story/${suggestion.storyId}/suggestions/${suggestion.id}`)
        const alreadyFlagged = suggestion.flaggedBy?.find((id) => id === userId)
        // console.log('alreadyFlagged ==>', alreadyFlagged)

        await runTransaction(fireStore, async (transaction) => {

            const sfDoc = await transaction.get(docRef);

            if (!sfDoc.exists()) {
                throw "Document does not exist! ==>";
            }

            let newFlags = 0
            if (alreadyFlagged) {
                newFlags = sfDoc.data().flags - 1
            } else {
                newFlags = sfDoc.data().flags + 1;
            }

            // console.log('newFlags ==>', newFlags)
            transaction.update(docRef, { flags: newFlags });

        });

        await updateFlaggedByArray(docRef, alreadyFlagged, userId)
        // console.log("Transaction successfully committed! ==>");
    } catch (e) {
        console.log("Transaction failed: ==>", e);
    }
}

//Share code 
const updateUpVotesArray = async (docRef: DocumentReference, alreadyVoted: string | undefined, userId: string | undefined) => {
    // find a better way of doing this alreadyVoted is ambiguous 
    if (alreadyVoted) {
        await updateDoc(docRef, {
            upVotes: arrayRemove(alreadyVoted)
        });
    } else {
        await updateDoc(docRef, {
            upVotes: arrayUnion(userId)
        });
    }
}

const updateFlaggedByArray = async (docRef: DocumentReference, alreadyFlagged: string | undefined, userId: string | undefined) => {
    // find a better way of doing this alreadyFlagged is ambiguous
    if (alreadyFlagged) {
        await updateDoc(docRef, {
            flaggedBy: arrayRemove(alreadyFlagged)
        });
    } else {
        await updateDoc(docRef, {
            flaggedBy: arrayUnion(userId)
        });
    }
}

export const saveSuggestion = async (storyId: string | undefined, text: string, userId: string) => {
    if (!validateSuggestion(text)) throw new Error('Text is not valid')

    return await addDoc(collection(fireStore, `story/${storyId}/suggestions`), {
        text: text,
        dateCreated: serverTimestamp(),
        votes: 0,
        flags: 0,
        storyId,
        userId
    })

    // return data
}

const validateSuggestion = (text: string): boolean => {
    if (!text) return false

    if (text.split('').length > 250) return false

    if (new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(text)) {
        return false
    }

    return true;
}

export const getAllSuggestionsListener = (storyId: string, updateSuggestions: (suggestions: Suggestion[]) => void, orderSuggestionBy?: string): () => void => {
    const unsubscribe: Unsubscribe = onSnapshot(
        buildSuggestionsQuery(storyId, orderSuggestionBy),
        (querySnapshot) => {
            let suggestions: Suggestion[] = []
            querySnapshot.forEach((doc) => {
                suggestions?.push({
                    id: doc.id,
                    dateCreated: doc.data().dateCreated,
                    text: doc.data().text,
                    votes: doc.data().votes,
                    storyId: doc.data().storyId,
                    upVotes: doc.data().upVotes,
                    userId: doc.data().userId,
                    selected: doc.data().selected,
                    flags: doc.data().flags,
                    flaggedBy: doc.data().flaggedBy
                })
            });

            if (orderSuggestionBy === SuggestionOrderBy.top) {
                suggestions.sort((a, b) => b.votes - a.votes)
            }

            updateSuggestions(suggestions)
        })

    return unsubscribe
}

export const buildSuggestionsQuery = (storyId: string, orderSuggestionBy?: string) => {

    if (orderSuggestionBy === SuggestionOrderBy.top || orderSuggestionBy === SuggestionOrderBy.recent) {
        let currentDate = DateTime.now()
        const date = new Date(currentDate.year, (currentDate.month - 1), currentDate.day, (currentDate.hour - 1))

        return query(
            collection(fireStore, `story/${storyId}/suggestions`),
            where('dateCreated', '>', date),
            orderBy('dateCreated', 'desc'),
        )
    }

    return query(
        collection(fireStore, `story/${storyId}/suggestions`),
        orderBy('dateCreated', 'desc'),
    )
}

export const getAllParagraphsListener = (storyId: string, updateParagraphs: (paragraphs: Paragraphs[]) => void): () => void => {
    const unsubscribe: Unsubscribe = onSnapshot(
        query(
            collection(fireStore, `story/${storyId}/paragraphs`),
            orderBy('dateCreated', 'asc')
        ),
        (querySnapshot) => {
            let paragraphs: Paragraphs[] = []
            querySnapshot.forEach((doc) => {
                paragraphs?.push({
                    id: doc.id,
                    dateCreated: doc.data().dateCreated,
                    text: doc.data().text,
                    suggestionId: doc.data().storyId,
                })
            });

            updateParagraphs(paragraphs)
        })

    return unsubscribe
}

export const authenticateAnonymousUser = async () => {
    const auth = getAuth();
    return await signInAnonymously(auth).catch((error) => {
        console.log('Error occured ==>', error.code, error.message)
    });
}

export const onAuthStateChangedListener = (updateUser: (uid: string) => void) => {
    const auth = getAuth();
    onAuthStateChanged(auth, (user) => {
        if (user) {
            const uid = user.uid;
            updateUser(uid)
            // ...
        } else {
            // something should happend here 
        }
    });
}

export const getCurrentStory = async (): Promise<Story | null> => {
    const querySnapshot = await getDocs(query(collection(fireStore, "story"), where('enabled', '==', true)));
    let story: Story | null = null;

    querySnapshot.forEach((doc) => {
        story = {
            id: doc.id,
            dateCreated: doc.data().dateCreated,
            theme: doc.data().theme,
            name: doc.data().name,
            text: doc.data().text
        }
    });

    return story
}

//Can probably move this to a cloud function.
export const getAllCompletedStories = async (): Promise<Story[] | []> => {

    try {
        const querySnapshot = await getDocs(query(collection(fireStore, "story"), where('completed', '==', true), orderBy('dateCreated', 'desc')));
        let stories: Story[] = [];
        let paragraphsPromise: Promise<QuerySnapshot<DocumentData>>[] = [];

        querySnapshot.forEach((doc) => {
            stories.push({
                id: doc.id,
                dateCreated: doc.data().dateCreated,
                theme: doc.data().theme,
                name: doc.data().name,
                text: doc.data().text
            })
        });

        if (stories.length === 0) return [];

        stories.forEach((story) => {
            paragraphsPromise.push(getDocs(query(
                collection(fireStore, `story/${story.id}/paragraphs`),
                orderBy('dateCreated', 'asc')
            ))
            );
        })

        const results = await Promise.all(paragraphsPromise)

        stories.forEach((story, index) => {
            let paragraphs: Paragraphs[] = [];
            results[index].forEach((doc) => {
                paragraphs?.push({
                    id: doc.id,
                    dateCreated: doc.data().dateCreated,
                    text: doc.data().text,
                    suggestionId: doc.data().storyId,
                })
            });

            story.paragraphs = paragraphs

        })

        return stories
    } catch (error) {
        throw new Error(`Fetching Stories error! ${error}`)
    }
}

export const formatTime = (dateCreated: Timestamp): string | null => {
    return DateTime.fromMillis(dateCreated.seconds * 1000).toRelative()
}

export const calculateStoryProgess = (dateCreated: Timestamp, updateProgress: (progress: number, timeLeft: string) => void) => {
    const dateStoryStarted = DateTime.fromMillis(dateCreated.seconds * 1000)
    const diff = DateTime.now().diff(dateStoryStarted, [(DEFAULT_STORY_TIME.unit as keyof DurationLikeObject)])
    const timeLeft = Math.round(24 - diff.hours)
    const percentage = Math.round((Math.round(diff.hours) / DEFAULT_STORY_TIME.value) * 100)

    updateProgress(percentage, `${timeLeft} hrs`)
}

export const calculateSuggestionProgess = (dateCreated: Timestamp, updateProgress: (progress: number, timeLeft: string) => void) => {
    const dateStoryStarted = DateTime.fromMillis(dateCreated.seconds * 1000)
    const diff = DateTime.now().diff(dateStoryStarted, ['hours', (DEFAULT_SUGGESTION_TIME.unit as keyof DurationLikeObject)])
    const timeLeft = Math.round(60 - diff.minutes)
    const percentage = Math.round(diff.minutes / 60 * 100)

    updateProgress(percentage, `${timeLeft} mins`)
}

export const testFunction = async () => {
    // const data = await getDocs(query(collection(fireStore, `story/ZDGxcPcBOBFUfEDmdcal/suggestions`),orderBy('votes', 'desc'), orderBy('dateCreated', 'desc'), limit(1)));
    // const timeStamp = {
    //     seconds: 1652961607,
    //     nanoseconds: 325000000
    // }

    // const data = await getDocs(query(collection(fireStore, `story/ZDGxcPcBOBFUfEDmdcal/suggestions`), where('dateCreated', '>', new Date(timeStamp.seconds * 1000)), orderBy('dateCreated', 'desc')));
    // console.log(data.docs.sort((a, b) => b.data().votes - a.data().votes)[0]);

    // if (data.size > 0) {
    //     console.log('Test ==>', data.docs)
    // } else {
    //     console.log('EMPTY LIST')
    // }

    return null
}
