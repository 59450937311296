import { useState, useEffect } from "react";
import {
    calculateStoryProgess,
    calculateSuggestionProgess,
} from '../service/main'

import { Story } from '../types'


export const useProgressBar = (currentStory: Story | null) => {
    const [intervalIds, setIntervalIds] = useState<NodeJS.Timer[]>([]);
    const [storyProgress, setStoryProgress] = useState<{ progress: number, timeLeft: string }>({progress: 0, timeLeft: ''})
    const [suggestionProgress, setSuggestionProgress] = useState<{ progress: number, timeLeft: string }>({progress: 0, timeLeft: ''})


    useEffect(() => {
        if (!currentStory) return;

        calculateStoryProgess(currentStory.dateCreated, (progress, timeLeft) => setStoryProgress({ progress, timeLeft }))
        calculateSuggestionProgess(currentStory.dateCreated, (progress, timeLeft) => setSuggestionProgress({ progress, timeLeft }))

        const intervals = []
        const minuteIntervalId = setInterval(() => {
            calculateSuggestionProgess(currentStory.dateCreated, (progress, timeLeft) => setSuggestionProgress({ progress, timeLeft }))
        }, 60 * 1000)

        const hourIntervalId = setInterval(() => {
            calculateStoryProgess(currentStory.dateCreated, (progress, timeLeft) => setStoryProgress({ progress, timeLeft }))
        }, 60 * 60 * 1000)

        intervals.push(minuteIntervalId)
        intervals.push(hourIntervalId)
        setIntervalIds(intervals)

        return () => {
            intervalIds.forEach((intervalId) => {
                clearInterval(intervalId)
            })
        }


    }, [currentStory])


    return { storyProgress, suggestionProgress }
}

