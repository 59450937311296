import React from 'react'

type Props = {
    title: string
}

const PanelHeader: React.FC<Props> = ({ title }) => {

    return (
        <div className="flex flex-row w-full justify-between items-center pt-2 pl-2 pr-4 bg-slate-50 py-2 mb-2">
            <div className="flex flex-row space-x-3">
                <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
            </div>

            <h4 className="text-sm font-bold text-slate-400">{title}</h4>
        </div>
    )

}


export default PanelHeader