
var sharebutton = document.getElementById('share-btn');

sharebutton?.addEventListener('click', () => {
    console.log('Clicked!!')
})

export const buildShareImage = (text: string): HTMLCanvasElement | undefined => {
    // text = "It was half-past twelve when I returned to the Albany as a last desperate resort. The scene of my disaster was much as I had left it. The baccarat-counters still strewed the table, with the empty glasses and the loaded ash-trays jsdvls jssbl bsfbbbdd"

    const canvasView = document.getElementById('canvas')

    // if (canvasView && canvasView?.children.length > 0) return;

    const canvas = document.createElement('canvas');

    canvas.width = 500
    canvas.height = 500

    let context = canvas.getContext('2d');
    if (!context) return;

    let color1 = randomRGBBA()
    let color2 = randomRGBBA()


    var grd = context.createLinearGradient(0, 0, canvas.width, canvas.width);
    grd.addColorStop(0, color1);
    grd.addColorStop(1, color2);

    // Fill with gradient
    context.fillStyle = grd;
    context.fillRect(0, 0, canvas.width, canvas.width);



    // Add text
    context.textAlign = 'center'
    context.fillStyle = "#ffffff"
    context.font = "20px monospace"
    context.textAlign = 'center'

    const txtToRender = createText(text, context, [], canvas.width)
    txtToRender.reverse().forEach((line, index) => {
        context?.fillText(line, canvas.width / 2 + 4, canvas.width / 2 + (20 * index), canvas.width);
    })

    context?.fillText('www.bitetales.com', canvas.width - 120, canvas.width - 20)
    return canvas

}

const randomRGBBA = (): string => {
    var o = Math.round, r = Math.random, s = 255;
    return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + 1 + ')';
}


const createText = (text: string, context: CanvasRenderingContext2D, formatedLines: string[] = [], maxWidth: number): string[] => {
    const lines = text.split(' ');
    let line: string = ''
    let spaceLeft = 0

    lines.forEach((word, index) => {
        const textWidth = Math.floor(context.measureText(word).width)

        if (textWidth < maxWidth && spaceLeft < maxWidth) {
            line = line.concat(`${word} `)
            spaceLeft = spaceLeft + textWidth
        } else {
            const newLines = lines.splice(index, lines.length - index).join(' ')
            createText(newLines, context, formatedLines, maxWidth)
        }
    })


    formatedLines.push(line)
    return formatedLines
}


export const copyImage = (text: string, onComplete: () => void) => {
    const canvas = buildShareImage(text);
    if (!canvas) return;

    copyCanvasContentsToClipboard(canvas, onComplete, (errror) => { console.log(`Error has occured ${errror}`) })
}

async function copyCanvasContentsToClipboard(canvas: HTMLCanvasElement, onDone: () => void, onError: (error: Error) => void) {
    canvas.toBlob((blob) => {

        if (!blob) {
            onError(new Error('No blob!'));
            return
        }

        let data = [new ClipboardItem({ [blob.type]: blob })];

        navigator.clipboard.write(data).then(function () {
            onDone();
        }, function (err) {
            onError(err);
        })
    });
    // const imageBlob = new Promise<Blob | null>((resolve, reject) => { canvas.toBlob((blob) => resolve(blob)) })
    // const blobResolved: Blob | null = await imageBlob

    // if (!blobResolved) return

    // let data = [new ClipboardItem({ [blobResolved.type]: blobResolved })];


}