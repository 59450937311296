import React, { useEffect, useState } from "react";
import { HeartIcon } from '@heroicons/react/solid'
import { copyImage } from '../service/share'
import { Suggestion } from "../types";
import '../css/shareModal.css';
import { useCallback } from "react";


type Props = {
    shareContent: Suggestion | null
    onClose: () => void,
}

const ShareModal: React.FC<Props> = ({ shareContent, onClose }) => {
    const [showSnackbar, setShowSnackbar] = useState(false)
    const [timeoutId, setTimeOutId] = useState<NodeJS.Timer | null>(null)

    const toggleSnackBar = useCallback(() => {
        setShowSnackbar(true);
        const myTimeout = setTimeout(() => setShowSnackbar(false), 2500);
        setTimeOutId(myTimeout)

    }, [])

    useEffect(() => {

        return () => {
            if (timeoutId) clearInterval(timeoutId)
        }

    }, [timeoutId])



    return (
        <>
            {shareContent ? (
                <>
                    <div
                        className={`justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none backdrop-blur-sm`}
                    >
                        <div className="relative w-auto my-6 mx-4 lg:mx-auto max-w-3xl">
                            {/*content*/}
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                {/*header*/}
                                <div className="flex items-center justify-between p-2 bg-slate-50  border-b border-solid border-slate-200 rounded-t">
                                    <div className="flex flex-row w-full space-x-3 pt-2 pl-2">
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                        <div className="h-3 w-3 rounded-full bg-slate-200" > </div>
                                    </div>
                                    <button
                                        className="p-1 ml-auto border-0 text-slate-500 hover:text-slate-300  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                                        onClick={onClose}
                                    >
                                        <span className="bg-transparent text-slate-500  h-6 w-6 text-2xl block outline-none focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative p-6 flex-auto">
                                    <div className="flex flex-row space-x-1">
                                        <h3 className="text-base font-medium animate-pulse text-blue-500">
                                            Share
                                        </h3>
                                    </div>

                                    <div className="flex flex-row space-x-3 items-center justify-center">
                                        <div>
                                            <p className="py-1 text-slate-500 text-sm">An image of the suggestion will be added to your clipboard </p>
                                        </div>
                                        <div>
                                            {/* Sharingbutton Copy */}

                                            <button id="share-btn" onClick={() => copyImage(shareContent.text, toggleSnackBar)} className="hover:bg-slate-400 hover:cursor-pointer group flex items-center justify-center rounded-md bg-slate-500 text-white text-sm font-medium pl-2 pr-3 py-4 shadow-sm space-x-2">
                                                <p className="text-white text-sm leading-relaxed"> Share</p>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4 space-x-2" viewBox="0 0 20 20" fill="currentColor">
                                                    <path d="M15 8a3 3 0 10-2.977-2.63l-4.94 2.47a3 3 0 100 4.319l4.94 2.47a3 3 0 10.895-1.789l-4.94-2.47a3.027 3.027 0 000-.74l4.94-2.47C13.456 7.68 14.19 8 15 8z" />
                                                </svg>
                                            </button>

                                        </div>
                                    </div>
                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end pr-4 border-t border-solid border-slate-200 rounded-b py-2">

                                    <p className="text-slate-500 text-sm leading-relaxed"> Built with</p>
                                    <HeartIcon className="h-4 w-4 text-blue-400 animate-bounce hover:bg-blue-100 hover:cursor-pointer" />
                                </div>
                            </div>
                        </div>
                    </div >
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    {console.log({ showSnackbar })}
                    <div id="snackbar" className={`${showSnackbar ? 'visible show bg-slate-500' : 'hidden'}`} >Copied suggestion to clipbaord</div>
                </>
            ) : null}
        </>
    );
}


export default ShareModal