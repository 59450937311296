export type Timestamp = {
    seconds: number,
    nanoseconds: number
}

export type Story = {
    id: string,
    dateCreated: Timestamp,
    theme: string,
    name: string,
    text: string,
    paragraphs?: Paragraphs[]
    Suggestions?: Suggestion[]
}

//TODO: Making user optional for now, remove before publish
export type Suggestion = {
    id: string,
    dateCreated: Timestamp,
    storyId: string,
    text: string,
    votes: number,
    userId: string,
    upVotes?: string[]
    selected?: boolean
    flaggedBy?: string[]
    flags?: number
}

export type Paragraphs = {
    id: string,
    dateCreated: Timestamp,
    suggestionId: string,
    text: string
}

export const SuggestionOrderBy = {
    top: 'top' as SuggestionOrderByType,
    recent: 'recent' as SuggestionOrderByType,
    all: 'all' as SuggestionOrderByType
}

export type SuggestionOrderByType = 'top' | 'recent' | 'all'




