import React from 'react'
import { ArrowCircleUpIcon, ClockIcon, ViewListIcon } from '@heroicons/react/solid'
import { SuggestionOrderBy, SuggestionOrderByType } from '../types';

type Props = {
    suggestionsOrder: SuggestionOrderByType,
    setSuggestionsOrder: (order: SuggestionOrderByType) => void
}

const SortSuggestions: React.FC<Props> = ({ suggestionsOrder, setSuggestionsOrder }) => {

    return (
        <div className="flex-2 flex flex-row space-x-2 lg:order-2 md:order-1 order-1 self-end">
            <button className={`
    hover:bg-blue-100 
    hover:cursor-pointer 
    flex 
    flex-col 
    justify-start 
    items-center 
    border-2 
    border-slate-100 rounded-md w-15 p-2 ${suggestionsOrder === SuggestionOrderBy.top ? "bg-slate-200" : ""}`}
                onClick={() => { setSuggestionsOrder('top') }}>
                <ArrowCircleUpIcon className="h-5 w-5 text-gray-500" />
                <span className="text-gray-500 text-xs">Top</span>
            </button>

            <button className={`
    hover:bg-blue-100 
    hover:cursor-pointer 
    flex 
    flex-col 
    justify-start 
    items-center 
    border-2 
    border-slate-100 rounded-md w-15 p-2 ${suggestionsOrder === SuggestionOrderBy.recent ? "bg-slate-200" : ""}`} onClick={() => { setSuggestionsOrder(SuggestionOrderBy.recent) }}>
                <ClockIcon className="h-5 w-5 text-gray-500 text-center" />
                <span className="text-gray-500 text-xs">Recent</span>
            </button>

            <button className={`
    hover:bg-blue-100 
    hover:cursor-pointer 
    flex 
    flex-col 
    justify-start 
    items-center 
    border-2 
    border-slate-100 rounded-md w-15 p-2 ${suggestionsOrder === SuggestionOrderBy.all ? "bg-slate-200" : ""}`} onClick={() => { setSuggestionsOrder(SuggestionOrderBy.all) }}>
                <ViewListIcon className="h-5 w-5 text-gray-500 text-center" />
                <span className="text-gray-500 text-xs">All</span>
            </button>
        </div>
    )

}


export default SortSuggestions